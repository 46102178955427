<template>
  <b-form @submit.prevent="updateProvider">
    <b-form-group label="Nom" label-for="name-input">
      <b-form-input
          id="name-input"
          v-model="$v.p.name.$model"
          type="text"
          required
          placeholder="Renseignez un nom"
          :state="$v.p.name.$dirty ? !$v.p.name.$invalid: null"
      />
      <b-form-invalid-feedback :state="!$v.p.name.$invalid">
        <span v-if="!$v.p.name.required">
            Vous devez renseigner ce champ
        </span>
        <span v-else-if="!$v.p.name.name">
            Vous devez renseigner un nom valide
        </span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Identifiant client OAuth" label-for="oauth-client-id-input">
      <b-form-input
          id="oauth-client-id-input"
          v-model="$v.p.oauthClientId.$model"
          type="text"
          required
          placeholder="Renseignez un identifiant de client OAuth"
          :state="$v.p.oauthClientId.$dirty ? !$v.p.oauthClientId.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.p.oauthClientId.$invalid">
          <span v-if="!$v.p.oauthClientId.required">
            Vous devez renseigner ce champ
          </span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>

import {required} from "vuelidate/lib/validators"
import {name} from "@/validators"

export default {
  props: {
    provider: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      p: Object.assign({}, this.provider) // copy to protect original value
    }
  },
  validations: {
    p: {
      name: {required, name},
      oauthClientId: {required}
    }
  },
  methods: {
    async updateProvider() {
      try {
        await this.$store.dispatch('providers/updateProvider', {type: 'google', provider: this.p})
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la mise à jour du fournisseur d'authentification Google",
          text: e.message,
        });
        return
      }
      await this.$swal.fire({
        icon: "success",
        title: "Fournisseur d'authentification Google modifié",
      });
    }
  }
}
</script>
