<template>
  <div>
    <UpdateGoogleProviderForm v-if="ready" :provider="provider"/>
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import UpdateGoogleProviderForm from "@/components/providers/UpdateGoogleProviderForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";
import {mapState} from "vuex";

export default {
  components: {
    AppPulsingIcon,
    UpdateGoogleProviderForm
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("providers/fetchProvider", {type: 'google', uid: this.uid});
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération du fournisseur d'authentification Google",
        text: e.message,
      });
      return;
    }
    this.ready = true;
  },
}
</script>

<style scoped>

</style>